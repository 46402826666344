<template>
    <el-carousel
        :autoplay="false"
        :interval="5000"
        ref="varousel"
        class="banner"
        :height="bannerHeight"
        arrow="never"
        trigger="click"
    >
        <el-carousel-item v-for="(item, index) in bannerInfo" :key="index">
            <el-row
                class="bgCommon"
                type="flex"
                justify="space-between"
                :style="{
                    backgroundImage: 'url(' + bannerInfo[index].img + ')',
                }"
            >
                <div class="bannerMain">
                    <el-col :span="24">
                        <div class="bannerTitle" :class="{'bannerTitleEn': ($i18n.locale==='en' && deviceType === 'MOBILE') }"  v-html="item.title"></div>
                        <div class="bannerText">
                            <p v-html="item.text1"></p>
                            <p v-html="item.text2"></p>
                            <p v-html="item.text3"></p>
                            <p v-html="item.text4"></p>
                        </div>
                        <div class="bannerBtn" @click="onLogin">
                            {{ item.btnInfo }}
                        </div>
                    </el-col>
                </div>

                <div class="bannerBot"></div>
            </el-row>
        </el-carousel-item>
    </el-carousel>
</template>
  
  <script>
export default {
    name: "index",
    components: {},
    data() {
        return {
            deviceType:'PC',
            bannerHeight:'450px'
        };
    },
    computed: {
        bannerInfo(){
            return [
                {
                    title: this.$t("banner.sec1.title"),
                    text1: this.$t("banner.sec1.text1"),
                    text2: this.$t("banner.sec1.text2"),
                    text3: this.$t("banner.sec1.text3"),
                    text4: this.$t("banner.sec1.text4"),
                    btnInfo: this.$t("banner.sec1.btnInfo"),
                    img: require("@/assets/images/newIndex/banner2.png"),
                },
                {
                    title: this.$t("banner.sec2.title"),
                    text1: this.$t("banner.sec2.text1"),
                    text2: this.$t("banner.sec2.text2"),
                    text3: this.$t("banner.sec2.text3"),
                    btnInfo: this.$t("banner.sec2.btnInfo"),
                    img: require("@/assets/images/newIndex/banner1.png"),
                },
            ]
        }
    },
    created() {},
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize)
    },
    deactivated () {
        window.removeEventListener('resize', this.resize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize)
    },
    methods: {
        resize () {
            if (document.body.clientWidth > 768) {
                this.deviceType = 'PC';
                this.bannerHeight="450px"
            } else {
                this.deviceType = 'MOBILE';
                this.bannerHeight="3rem"
            }
        },
        onLogin() {
            this.$emit("onLogin");
        },
    },
};
</script>
  
  <style scoped lang="less">
.banner {
    width: 100%;
    height: 450px;
    background: linear-gradient(
        270deg,
        rgba(26, 40, 86, 0.1) 0%,
        rgba(26, 40, 86, 0.2) 38%,
        rgba(26, 40, 86, 0.5) 76%,
        rgba(26, 40, 86, 0.3) 100%
    );
    position: relative;

    /deep/.bgCommon {
        width: 100%;
        height: 450px;
        background-size: cover;
        background: linear-gradient(
            270deg,
            rgba(26, 40, 86, 0.1) 0%,
            rgba(26, 40, 86, 0.2) 38%,
            rgba(26, 40, 86, 0.5) 76%,
            rgba(26, 40, 86, 0.3) 100%
        );
    }

    /deep/ .el-carousel__indicators {
        padding-bottom: 15px;
    }
    /deep/ .el-carousel__indicators > li {
        margin-right: 10px;
    }

    /deep/ .el-carousel__indicators li button {
        width: 32px;
        height: 4px;
    }

    /deep/ .el-carousel__indicators li.is-active button {
        width: 32px;
        height: 4px;
        background-color: #fff;
    }

    // /deep/ .ant-carousel .slick-dots-bottom {
    //   bottom: 32px;
    // }

    .bannerMain {
        width: 1200px !important;
        padding: 104px 32px 0 0px;
        color: #ffffff;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .bannerTitle {
            font-size: 32px;
            font-weight: 500;
        }

        .bannerText {
            font-size: 16px;
            font-weight: 400;
            margin: 16px 0 24px;

            p {
                margin-bottom: 4px;
            }
        }

        .bannerBtn {
            width: 129px;
            height: 46px;
            background: #3f7df7;
            border-radius: 4px;
            line-height: 46px;
            text-align: center;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 768px) {
    .banner {
        width: 100%;
        height: 3rem;
        /deep/.bgCommon {
            width: 100%;
            height: 3rem;
            background-position-x: center;
            background-size:200%;
            background-repeat: no-repeat;
        }
        /deep/ .el-carousel__indicators {
            padding-bottom: 0;
        }
        /deep/ .el-carousel__indicators > li {
            margin-right: 0px;
        }
        .bannerMain {
            width: 100%;
            color: #ffffff;
            padding: 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding-top:.5rem;
            .bannerTitle {
                padding:0 .2rem;
                font-size: 18px;
                text-align: center;
                height:1.2rem;
            }
            .bannerTitleEn{
                text-align: left;
            }
            .bannerText {
                display: none;
                // padding: .2rem;
                // font-size: 14px;
                // margin: 0;
                // line-height: 25px;
                // .sup{
                //     line-height: 0;
                // }
                // p {
                //     display: inline;
                // }
            }

            .bannerBtn {
                margin: 0 auto;
                width: 1.80rem;
                height: .66rem;
                line-height: .66rem;
            }
        }
    }
}
</style>