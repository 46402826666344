export default{
    login:{
        slogan1:'<p>可信时间戳<sup>®</sup></p><p>随时保护您的商业秘密和知识产权</p>',
        slogan2:'——首都知识产权服务业协会',
        tab1:'账号密码登录',
        tab2:'手机动态码登录',
        phone:'请输入您的手机号',
        verPhone:'请输入正确的手机号',
        code:'请输入验证码',
        sendCode:'验证码已发送，请注意接收',
        getCode:'获取验证码',
        againGetCode:'重新获取',
        userName:'请输入您的用户名/手机号/邮箱',
        verUserName:'请输入正确的手机号或邮箱',
        userName2:'请输入您的手机号/邮箱',
        password:'请输入您的密码',
        verPassword:'请输入6-20位的字母或数字',
        confirmPwd:'请确认新密码',
        contrastPwd:'两次密码不一致',
        varSlide:'请先进行滑块验证',
        agreement:'请阅读并勾选协议',
        signIn:'登录',
        signUp:'注册',
        read:'我已阅读并接受',
        regAgreement:'《用户注册与使用协议》',
        and:'及',
        privacyAgreement:'《隐私保护协议》',
        goReg:'没有账号？去注册',
        goReg2:'账户未注册,请先注册',
        forgetPwd:'忘记密码？',
        goLogin:'有账号，去登录',
        goLogin2:'完成并登录',
        goLogin3:'返回登录',
        vError:'验证失败',
        authenticate:'验证身份',
        setPwd:'设置新密码',
        over:'完成',
        next:'下一步',
        previous:'返回上一步',
        setPwdSuccess:'密码重置成功！',
        regSuccess:'注册成功',
        alreadyReg:'该账号已用可信时间戳账号注册，请直接登录',
    }
}