export default{
    header:{
        logo:'Intellectual Property Protection Platform',
        login:'Sign in/out',
        signIn:'Apply',
    },
    banner:{
        sec1:{
            title: "Protect your business secret and intellectual property by UniTrust TimeStamp",
            text1:'Business secret and intellectual property are the core competitiveness of enterprises.\n',
            text2: "Apply UniTrust TimeStamp for all kinds of data and documents timely.\n",
            text3: "Obtain ownership certificate safely，confidentially and quickly.",
            text4: "Protect your legitimate rights by laws.",
            btnInfo: "APPLY",
        },
        sec2:{
            title: "UniTrust TimeStamp · Protect every idea or work of the original",
            text1:'All creations are valuable.\n',
            text2: "<p style='margin-bottom: 4px;'>Apply for UniTrust TimeStamp at any time from the beginning of creativity to the completion of the work,</p>and obtain the original certificate which can get the judicial recognition.",
            text3: "Protect your intellectual achievements with laws.",
            btnInfo: "APPLY",
        }
    },
    display:{
        sec1:{
            title:"Judicial Recognltion",
            // title: "JUDICIAL RECOGNLTION"
        },
        sec2:{
            title: "WIPO-NCAC Copyright Award",
            // title: 'WIPO-NCAC COPYRIGHT AWARD'
        },
        sec3:{
            title: "Safe and confidential",
            // title: 'SAFE AND CONFIDENTIAL'
        }
    },
    iprWork:{
        btnInfo: "PROTECT",
        iprWorkInfo:{
            title:'What can UniTrust TimeStamp do',
            listInfo:{
                sec1:{
                    leftTitle: "Business Secrets Protection",
                    leftInfo:"Business secrets are the core competitiveness of enterprises, directly related to survival, development and competitive advantage.",
                    rightTitle:"Enterprise employees leaking secrets, outsiders stealing, luring, threatening or using other improper means to obtain the enterprise trade secrets occur repeatedly,  which are hidden deeply, and difficult to collect evidence or to litigate. UniTrust TimeStamp intellectual property protection platform can desensitize and authenticate corporate business secrets from the moment of their creation. There is no need to upload documents to the platform which maximizes the privacy and security of the enterprises' business secrets. Once the business secrets are infringed, there is a basis to defend the rights. Enterprise property and reputation are protected from damage.",
                    rightList1: {
                        list1:'Software',
                        list2:'Design',
                        list3:'Drawing',
                        list4:'Modle',
                        list5:'Formula',
                        list6:'Process',
                        list7:'Pattern',
                        list8:'Electronic Data',
                        list9:'Test Record',
                        list10:'Purchase Type',
                        // list11:'Purchase model',
                        list11:'Technical Data',
                        list12:'Custom price',
                        list13:'Classified production equipment',
                        list14:'Instrument',
                        list15:'Mould',
                        title: "Technical secrets",
                    },
                    rightList2: {
                        list1:'Business secrets',
                        list2:'MANAGE FILES',
                        list3:'Decision Information',
                        list4:'Conduct',
                        list5:'R&D',
                        list6:'RFp',
                        list7:'Contract',
                        list8:'CUSTOMER',
                        list9:'Supplier list',
                        list10:'SALES',
                        list11:'Marketing plan',
                        list12:'Marketing Manual',
                        list13:'Logistics',
                        list14:'Financial statement',
                        list15:'Salary information',
                        title: "Business secrets",
                    },
                    
                },
                sec2:{
                    leftTitle: "Copyright Protection",
                    leftInfo: "Starting with ideas, all creations are valuable.",
                    rightTitle:'Why do you claim that you originated an idea or work? How can you prove that you are the first to come up with the idea? How can you demonstrate your ownership and the time of creating the work if it is infringed? Now, apply for the UniTrust TimeStamp certification and get an "electronic birth certificate" for your work, as well as a judicially recognized proof of ownership to effectively protect your intellectual property!',
                    rightList1: {
                        list1:'Art',
                        list2:'Writings',
                        list3:'Modle',
                        list4:'Photography',
                        list5:'Software',
                        list6:'Drawing',
                        list7:'Music',
                        list8:'Video',
                        list9:'Game',
                        list10:'Research',
                        list11:'Academic',
                        list12:'Oral account',
                        title: "Application scenarios",
                    },
                },
                sec3:{
                    leftTitle: "Trademark / Patent Protection",
                    leftInfo: "To protect the trademark is to protect the reputation of the enterprise, and to protect the patent is to protect the enterprise’s property.",
                    rightTitle:"Trademark is the most important intangible asset of a company, which is the embodiment of corporate reputation and commercial value. If the trademark is infringed, how can you preserve the evidence? Is a trademark registration license alone adequate? Now, you can apply for UniTrust TimeStamp certification for your trademark's creativity, design concept, management, usage, and influence to obtain judicially recognized proof of antecedence, to protect your brand a competitive advantage in the market.",
                    rightTitle2:'The basis for the patent system is that in exchange for the inventor’s disclosure of his or her idea/design to the public, it’s protected. But how to protect the idea/design before it becomes disclosed patent? Once leaked by internal people or stolen by others, the novelty of the idea/design is likely to be lost. It could even be applied for a patent by others first, which will cause incalculable loss to the enterprise. At the beginning of a patent project, it is necessary to apply for UniTrust TimeStamp certification for patent documents, design schemes, models, design drawings, technical parameters and other information, so as to obtain proof of the entire process of the "birth" of a patent, which effectively protects the legitimate rights and interests of enterprises.',
                    rightList1: {
                        list1:'Trademark design',
                        list2:'Trademark management',
                        list3:'Registration',
                        list4:'Usage',
                        list5:'Maintenance',
                        list6:'Effect',
                        title: "Application scenarios",
                    },
                    rightList2: {
                        list1:'Patent file',
                        list2:'Drawing',
                        list3:'Modle',
                        list4:'Design',
                        list5:'Technical Data',
                        list6:'Test record',
                        list7:'Thesis',
                        title: "Application scenarios",
                    },
                },
                sec4:{
                    leftTitle: "Digital Asset Protection",
                    leftInfo: "In the context of the digital economy, enterprises will face a huge challenge in the authentication and protection of their digital assets.",
                    rightTitle:"Data shows that more than 50% of enterprises had suffered from digital asset leaks. Every electronic data generated by enterprises in daily operations, such as electronic documents, virtual accounts, user tags, IP assets, etc., has high economic value. If the ownership of these digital assets is unclear, a digital asset leak could cause significant financial loss to the enterprise. With UniTrust TimeStamp, you can instantly apply for a certificate of antecedence for the electronic data generated by your company to protect your digital assets and business secrets.",
                    rightList1: {
                        list1:'Account number',
                        list2:'Domain name',
                        list3:'Database',
                        list4:'E-file',
                        list5:'Cloud',
                        list6:'BI',
                        list8:'Cybermoney',
                        list9:'Game equipment',
                        list10:'NFT',
                        title: "Application scenarios",
                    },
                },
            }
        }
    },
    solutionInfo:{
        title:'Customized solutions',
        infoBtn: "Acquire Solutions",
        solutionList:{
            sec1:{
                title: "Internet",
                infoTitleList:{
                    list1:'Trade secret',
                    list2:'Original design',
                    list3:'Product Design',
                    list4:'Business planning',
                    list5:'Advertising Proposal',
                },
                infoMain:"As the competition among Internet companies becomes increasingly fierce in China, it has become normal for employees to jump ship to competing companies. Data shows that 80% of trade secrets cases involve employees leaking information.",
                infoMain1: "How to effectively protect the company's business secrets, prevent the leaking of confidentiality by departing employees, and vicious competition ",
                infoMain2:"from rivals has become an important issue that all Internet companies need to seriously deal with. Enterprises can use the UniTrust TimeStamp",
                infoMain3:" intellectual property protection platform to authenticate documents from the very beginning of trade secrets. Even if the secrets are leaked by employees, companies can produce clear evidence of ownership and judicial recognition to minimize the loss.",
                infoMain4: "",
            },
            sec2:{
                title: "Software",
                infoTitleList:{
                    list1:'Program code',
                    list2:'UI',
                    list3:'Requirement specification',
                    list4:'Test sample',
                    list5:'Product manual',
                    list6:'Database',
                    list7:'Flow chart',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"Software products are the important core competence and intellectual assets of enterprises. Enterprises often invest a substantial amount of money in development. However, these digital assets with independent intellectual property rights have long been subject to illegal infringement such as internal staff leaks and competitors' plagiarism. Enterprises often fail to defend their rights due to insufficient primary evidence, which brings huge losses to enterprises.",
                infoMain1: "UniTrust TimeStamp can effectively solve the problems concerning the difficulty of confirming and proving rights in the software industry, and achieve substantial protection of intellectual property rights of software products.",
                infoMain2:" ",
                infoMain3:"",
            },
            sec3:{
                title: "Scientific institution",
                infoTitleList:{
                    list1:'Theoretical achievements',
                    list2:'Technological achievements',
                    list3:'Invention patent',
                    list4:'Technical secret',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"Scientific research institutions are an important part of the national innovation system and an important source of scientific and technological innovation output. With the continuous improvement of China's innovation capacity, scientific research institutions generally reserve a large number of patents and other intellectual property rights.",
                infoMain1: "UniTrust TimeStamp protects the years of work of research institutions and laboratories from being used for free by others.",
                infoMain2:"",
            },
            sec4:{
                title: "FMCG",
                infoTitleList:{
                    list1:'Trade secret',
                    list2:'Trademark',
                    list3:'Drawing',
                    list4:'Design patent',
                    list5:'Advertising',
                    list6:'Instructions',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"As Chinese citizens’ willingness to consume increases and consumption escalates, competition in the same category of consumer goods is becoming increasingly fierce. Illegal acts such copycat branding and counterfeiting happen more frequently, which have seriously infringed on the reputation and legitimate rights of enterprises.",
                infoMain1:"By using the UniTrust TimeStamp intellectual property protection platform, the whole process of design, manufacturing, promotion and sales can be protected, which can help enterprises recover economic losses and reduce the cost of rights protection.",
                infoMain2:"",
                infoMain3: "",
            },
            sec5:{
                title: "Entertainment",
                infoTitleList:{
                    list1:'Live streaming',
                    list2:'Film and Television',
                    list3:'Script',
                    list4:'Novel',
                    list5:'Cultural',
                    list6:'Song',
                    list7:'Trademark',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"In 2022, with the explosive growth of the short video/live streaming, the cultural and entertainment industry has become the second-largest economy in the world. At the same time, legal disputes over intellectual property rights in the cultural and entertainment industry have occurred frequently, such as video piracy, live-streaming infringement, script plagiarism, pirated copycat derivative products and IP, and design infringement. There is even a typical case of a movie being fined RMB 140,000 for an infringement of seven words.",
                infoMain1:"You can use UniTrust TimeStamp to prove the priority of script, video creation, and intellectual property content, with copyright and trade secret as the core, and build a perfect intellectual property protection system to effectively enhance the business value and competency of your company.",
                infoMain2:"",
                infoMain3:"",
                infoMain4: "",
            },
            sec6:{
                title: "Biotechnology",
                infoTitleList:{
                    list1:'Trademark',
                    list2:'Patent',
                    list3:'Trade secret',
                    list4:'Research findings',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"In 2022, the National Development and Reform Commission issued “the 14th Five-Year Plan for Bioeconomy Development”. It is proposed to strengthen the protection of intellectual property rights, cultivate high-value patents, and promote the realization of intellectual property values and the implementation of scientific and technological achievements.",
                infoMain1: "A company's research and development institution serve as the soul of innovation. When a technical result is produced, use UniTrust TimeStamp to protect the result immediately. When the product reaches the market, it can effectively and timely stop possible infringement or defend the rights and interests of the enterprise.",
                infoMain3:"",
                infoMain4: "",
            },
            sec7:{
                title: "Manufacturing",
                infoTitleList:{
                    list1:'Trade secret',
                    list2:'Research findings',
                    list3:'Invention patent',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"During the 13th Five-Year Plan period, the Ministry of Industry and Information Technology, together with the National Intellectual Property Administration, issued “the Action Plan on Intellectual Property Rights in Manufacturing”. It emphasizes the deployment and promotion of the manufacturing industry in terms of intellectual property creation, protection and application.",
                infoMain1:"The technical secrets generated in the manufacturing industry are usually practical and can bring direct benefits to the right holder, which will cause significant losses if stolen. UniTrust TimeStamp protects the technical secrets and invention patents of the manufacturing industry from the source, which provides evidence for inventions and the basis for rights protection, and comprehensively improves the awareness and ability of intellectual property protection of enterprises.",
                infoMain2:"",
                infoMain3:"",
            },
            sec8:{
                title: "Motor-dom",
                infoTitleList:{
                    list1:'Trade secret',
                    list2:'Patent',
                    list3:'Trademark',
                    list4:'Digital asset',
                    list5:'Ircuit design',
                    list6:'Axure',
                    list7:'Operation manual',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"Innovation is the key driver of the automotive industry, which is typically an intellectual property-intensive industry. With the Internet and other technology giants entering the automotive industry, driver-less technology, chip manufacturing, battery technology, and safe driving continuously upgrade. The industry is experiencing unprecedented development boom and challenges. At the same time, many car companies have suffered huge losses due to leaks from departing employees or unfair competition from rivals.",
                infoMain1:"UniTrust TimeStamp can help major car companies to verify their trade secrets, patent designs, trademarks and digital assets in a timely and effective manner, and protect their legal rights from being infringed.",
                infoMain2:"",
                infoMain3:"",
            },
            sec9:{
                title: "Gaming",
                infoTitleList:{
                    list1:'Storyline',
                    list2:'Game code',
                    list3:'Art UI',
                    list4:'Video animation',
                    list5:'Level Design',
                    list6:'Software code',
                    list7:'Business secret',
                    list8:'Trademark',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"Along with the booming development of China's game industry in recent years, closely related intellectual property issues have emerged. The game industry has been the hardest hit by frequent infringement and piracy. Various elements in games, such as game logo, storyline, characters, video animation, artistic user interface, level setting, software code, etc., involve multiple intellectual property rights such as copyright, trademark right, patent rights and trade secret rights. Due to the special nature of elements contained in games, enterprises often face problems of unclear attribution of the evidence of rights, and high cost of rights defense after encountering infringement.",
                infoMain1:"UniTrust TimeStamp can protect the entire design and development process of a game, starting from the idea and creativity. When confronted with infringement, you can prove the whole process of a game's birth and effectively defend your legal rights.",
                infoMain2:"",
                infoMain3:"",
                infoMain4: "",
            },
            sec10:{
                title: "Catering",
                infoTitleList:{
                    list1:'Trademark',
                    list2:'Design patent',
                    list3:'Formulation optimization',
                    list4:'Product Design',
                    list5:'Advertising',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"Food and beverage industry infringement cases have always been commonplace. From the protracted “herbal tea” debate, the “Chinese slag pancake” dispute years ago, to the recent “Hu Spicy Soup” case, trademark counterfeiting, recipe copying, copycat stores and a series of infringement behavior make various catering enterprises suffer.",
                infoMain1:"How can we effectively combat counterfeiting and recover the benefits that enterprises deserve? With UniTrust TimeStamp®, you can protect your registered trademarks, recipes, and appearance patents and obtain instant proof of antecedence. So that when you encounter infringement, you can use an efficient tool to defend your legal rights.",
                infoMain2:"",
                infoMain3:"",
            },
        }
    },
    principleTitle:'Working Principle Of UniTrust TimeStamp Intellectual Property Protection Platform',
    videoPlay:'Play',
    timestampCase: {
        title: 'Typical Legal Precedents Using TimeStamp'
    },
    commonProblems: {
        title: 'Common Problems'
    },
    footer:{
        webName:'Capital Intellectual Property Services Association',
        address:'Address: Floor 3, Block A, Zhongguancun Intellectual Property Building,<br> No.21 Haidian South Road, Haidian District, Beijing, China',
        email:'Email: ipfs@capitalip.org',
        ICP:'京ICP备NO.15043949-1',
        record:'京公网安备 NO.11010802032281',
        support:'Service Support',
    },
    newUser:'New User Benefits'
}