import { Message } from 'element-ui'

export function diaMessage(msg, icon = 'error', time = "3") {
    if (icon == 'error') {
        this.$layer.msg('<p><img class="mylayer" src="' + require('@/assets/images/login/error-icon.png') + '"/>' + msg + '</p>', { time })
    } else if (icon === 'wrong') {
        this.$layer.msg('<p><img class="mylayer" src="' + require('@/assets/images/login/wrong.png') + '"/>' + msg + '</p>', { time })
    } else {
        this.$layer.msg('<p><img class="mylayer" src="' + require('@/assets/images/login/success-icon.png') + '"/>' + msg + '</p>', { time })
    }

}
export function Mymessage(msg, type = 'error', duration = 3000) {
    Message({
        message: msg,
        type,
        duration
    })
}

/**
 * UrlSearch=>
 * @param {(window.url)}
 * @returns {String||Number}
 */
export function GetUrlParam(paraName) {
    var url = document.location.toString();
    var arrObj = url.split("?");

    if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split("=");

            if (arr != null && arr[0] == paraName) {
                return arr[1];
            }
        }
        return "";
    } else {
        return "";
    }
}

export function endWith(proStr, str) {
    if (str == null || str == "" || proStr.length == 0 || str.length > proStr.length)
        return false;
    if (proStr.substring(proStr.length - str.length) == str)
        return true;
    else
        return false;
    return true;
}

export function urlHost(url) {
    let domain = url.split('/'); //以“/”进行分割
    if (domain[2]) {
        domain = domain[2];
    } else {
        domain = ''; //如果url不正确就取空
    }
    return domain;
}

export function urlDelP(url, name) {
    var urlArr = url.split('?');
    if (urlArr.length > 1 && urlArr[1].indexOf(name) > -1) {
        var query = urlArr[1];
        var obj = {}
        var arr = query.split("&");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].split("=");
            obj[arr[i][0]] = arr[i][1];
        };
        delete obj[name];
        var urlte = urlArr[0] + '?' + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
        return urlte;
    } else {
        return url;
    };
}