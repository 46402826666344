import request from "./index";
import qs from 'qs'

export const getMeApi = () =>
    request({
        method: 'get',
        url: '/me?hidden=true',
        stopLoading: true
    });

export const loginApi = (data) => 
    request({
        method: 'post',
        url: '/partner/login',
        data,
    });

export const sendMsgApi = (data) => 
    request({
        method: 'post',
        url: '/sendMsg',
        data,
    });

export const signupApi = (data) => 
    request({
        method: 'post',
        url: '/partner/signup',
        data,
    });

export const signProtocol = (data) =>
    request({
        method: 'put',
        url: `/partner/signProtocol?${qs.stringify(data)}`,
    });

export const recoverApi = (data) => 
    request({
        method: 'post',
        url: '/recover',
        data,
    });

export const logoutApi = (data) => 
    request({
        method: 'post',
        url: '/partner/logout',
        data,
    });

export const changePwdApi = (data) => 
    request({
        method: 'post',
        url: '/changePassword',
        data,
    });

export const sendDingTalk = (data) => 
    request({
        headers: {
            'content-type': "application/json;charset=UTF-8"
        },
        method: 'post',
        url: '/sendDingTalk',
        data,
    });

export const sendSmsCode = (params) => 
    request({
        method: 'get',
        url: '/sendSmsCode',
        params,
    });
