<template>
    <div class="wrap">
        <div class="header">
            <div class="cnt">
                <div class="logo">
                    <img src="@/assets/images/capital/bigLogo.png" alt="">
                    <b v-if="$i18n.locale==='en' && deviceType === 'MOBILE'">IPR</b>
                    <b v-else>{{$t('header.logo')}}</b>
                </div>
                <div class="right">
                    <div class="changeLang" @click="changeLang">
                        <span v-if="$i18n.locale==='en'"><img src="@/assets/images/capital/zh.png" alt=""><b>中文</b></span>
                        <span v-else><img src="@/assets/images/capital/en.png" alt=""><b>English</b></span>
                    </div>
                    <div class="login">
                        <span @click="onLogin" v-if="!token">{{$t('header.login')}}</span>
                        <span @click="onLogin" v-else>{{$t('header.signIn')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bannerWrap">
            <Carousel @onLogin="onLogin"></Carousel>
        </div>
        <div class="address boxPC">
            <div class="addressMain">
                <el-row type="flex" justify="space-between" class="">
                    <el-col
                        :class="$i18n.locale==='zh'?'el-col':'el-col2'"
                        @click.native="addressClick(index)"
                        v-for="(item, index) in addressInfo"
                        :key="index"
                        :span="index === 0? 7: index === 1? 9: index === 2? 6: ''"
                    >
                        <div class="left bgCommon" :style="{backgroundImage: 'url(' + item.img + ')'}"></div>
                        <div class="addressTitle" v-html="item.title" />
                        <div class="addressText" v-if="$i18n.locale=='zh'">
                            <p v-html="item.info" v-if="index !== 0"></p>
                            <p v-else>
                                使用可信时间戳证据的裁判文书{{accountNum.precedentTotal}}件
                            </p>
                        </div>
                        <div class="addressBot" v-if="index === 0 && $i18n.locale==='zh'">
                            (数据来源：中国裁判文书网)
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="boxMobile address-M">
            <ul>
                <li v-for="(item, index) in addressInfo" :key="index">
                    <div class="icon" :style="{backgroundImage: 'url(' + item.img + ')'}"></div>
                    <div class="des">
                        <div class="addressTitle" v-html="item.title" />
                        <div class="addressText" v-if="$i18n.locale=='zh'">
                            <p v-html="item.info" v-if="index !== 0"></p>
                            <p v-else>
                                使用可信时间戳证据的裁判文书{{accountNum.precedentTotal}}件
                            </p>
                        </div>
                        <div class="addressBot" v-if="index === 0 && $i18n.locale==='zh'">
                            (数据来源：中国裁判文书网)
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 可信时间戳®能做什么 -->
        <div class="iprWork">
            <div class="workMain">
                <div class="workTitle"  v-html="iprWorkInfo.title"></div>
                <div class="workList">
                    <el-row type="flex" justify="space-between" class="aCol">
                        <el-col class="left" :span="8">
                            <div
                                v-for="(item, index) in iprWorkInfo.listInfo"
                                :key="index"
                                @click="workClick(item, index)"
                                :class="(workActive === index)? ($i18n.locale==='zh'?'workListLeft workLeftActive' :'workLeftEnActive workListLeft'):'workListLeft'"
                                @mouseover="mouseOver()"
                                @mouseleave="mouseLeave()"
                            >
                                <div class="leftTitle" v-html="item.leftTitle" :class="{leftTitleActive: workActive === index}">
                                </div>
                                <div
                                    class="leftInfo"
                                    v-html="item.leftInfo"
                                    :class="{baseBreak:workActive !== index? 'baseBreak': '',}"
                                ></div>
                                <div
                                    class="leftImg bgCommon"
                                    :style="{backgroundImage:'url(' + item.leftImg + ')'}"
                                    v-if="workActive === index"
                                ></div>
                                <el-progress
                                    :percentage="percentage"
                                    color="#3F7DF7"
                                    class="line"
                                    v-if="workActive === index"
                                ></el-progress>
                            </div>
                        </el-col>
                        <el-col :span="16">
                            <div
                                class="workListRight"
                                v-for="(item, index) in iprWorkInfo.listInfo"
                                :key="index"
                                :class="{workRightActive: workActive === index,}"
                                @mouseover="mouseOver()"
                                @mouseleave="mouseLeave()"
                            >
                                
                                <div v-if="workActive === index">
                                    <div class="rightTitle" v-html="item.rightTitle"></div>
                                    <div class="rightListMain">
                                        <div class="rightListTitle">
                                            {{ item.rightList1.title || "" }}
                                        </div>
                                        <div class="rightList1" v-for="(item2) in item.rightList1.list" :key="item2">
                                            {{ item2 }}
                                        </div>
                                        <p>......</p>
                                        <div class="col" v-if="workActive === 2"></div>
                                        <div class="rightTitle rightTitleN" v-html="item.rightTitle2" v-if="item.rightTitle2"></div>
                                        <div class=" rightListTitle rightListTitle2" v-if="item.rightList2">
                                            {{ item.rightList2.title || "" }}
                                        </div>
                                        <div v-if="item.rightList2" style="width: 90%">
                                            <div class="rightList1" v-for="(item2) in item.rightList2.list" :key="item2">
                                                {{ item2 }}
                                            </div>
                                            <p>......</p>
                                        </div>
                                        <div class="workBtn" @click="onLogin">
                                            {{$t('iprWork.btnInfo')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="workLogo bgCommon" :style="{backgroundImage:'url(' + iprWorkInfo.workLogo + ')'}" @mouseover="mouseOver()" @mouseleave="mouseLeave()">
                    </div>
                </div>
            </div>
        </div>
        <div class="boxMobile iprWork-M">
            <div class="workTitle" :class="{'workTitleEn': $i18n.locale==='en' }" v-html="iprWorkInfo.title"></div>
            <div class="tabCard" ref="myTabCardRef">
                <div 
                    class="tabCard-item" 
                    v-for="(item, index) in iprWorkInfo.listInfo" 
                    :ref="`tabCard${index}Ref`"
                    :class="{ active: index === workActive2 }" 
                    :key="item.leftTitle"
                    @click="tabCardClick(index)"
                >
                    <div class="tabCard-title">
                        {{ item.leftTitle }}
                    </div>
                    <div class="tabCard-text" :class="{'tabCard-text-En': $i18n.locale==='en' }" v-html="item.leftInfo"></div>
                    <div
                        class="leftImg"
                        :style="{backgroundImage:'url(' + item.leftImg + ')'}" 
                        v-if="workActive2 === index">
                    </div>
                </div>
            </div>
            <div class="tabCnt">
                <div
                    class="workListRight"
                    v-for="(item, index) in iprWorkInfo.listInfo"
                    :key="index"
                    :class="{workRightActive: workActive2 === index}"
                >
                    <div v-if="workActive2 === index">
                        <div class="rightTitle" v-html="item.rightTitle"></div>
                        <div class="rightListMain">
                            <div class="rightListTitle">
                                {{ item.rightList1.title || "" }}
                            </div>
                            <div class="rightList1" v-for="(item2) in item.rightList1.list" :key="item2">
                                {{ item2 }}
                            </div>
                            <p>......</p>
                            <div class="rightTitle rightTitleN" v-html="item.rightTitle2" v-if="item.rightTitle2"></div>
                            <div class=" rightListTitle rightListTitle2" v-if="item.rightList2">
                                {{ item.rightList2.title || "" }}
                            </div>
                            <div v-if="item.rightList2" style="width: 90%">
                                <div class="rightList1" v-for="(item2) in item.rightList2.list" :key="item2">
                                    {{ item2 }}
                                </div>
                                <p>......</p>
                            </div>
                            <div class="workBtn" @click="onLogin">
                                {{$t('iprWork.btnInfo')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 定制化解决方案 -->
        <div class="solution bgCommon" :style="{backgroundImage:'url(' +solutionInfo.solutionList[solutionActive].solutionIcon +')'}">
            <div class="solutionBC"></div>
            <div class="solutionMain">
                <div class="solutionTitle">{{ $t('solutionInfo.title') }}</div>
                <div class="solutionNav">
                    <p
                        @click="prevBtn"
                        class="bgCommon leftIcon"
                        :style="{backgroundImage:'url(' + solutionInfo.leftIcon + ')'}"
                        v-if="solutionActive !== 0"
                    />
                    <div class="navScroll">
                        <swiper :options="swiperOption" ref="solutionSwiper" class="swiperInfo">
                            <swiper-slide class="navText" v-for="(item, index) in solutionInfo.solutionList" :key="index">
                                <p :class="{solutionActive:solutionActive === index}" @click="solutionClick(item, index)">
                                    {{ item.title }}
                                </p>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <p
                        @click="nextBtn"
                        class="bgCommon rightIcon"
                        :style="{backgroundImage:'url(' + solutionInfo.rightIcon + ')',}"
                        v-if="solutionActive !==solutionInfo.solutionList.length - 1"
                    />
                </div>
                <div class="solutionInfo">
                    <div
                        class="infoText"
                        v-for="(item2, index2) in solutionInfo.solutionList[solutionActive].infoTitleList"
                        :key="index2"
                        :class="{solutionActive: solutionActive === index2}"
                    >
                        <p>{{ item2 }}</p>
                        <span v-if="index2 <solutionInfo.solutionList[solutionActive].infoTitleList.length -1">|</span>
                    </div>
                    <div class="infoMain infoMain1" v-html="solutionInfo.solutionList[solutionActive].infoMain"></div>
                    <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain1"></div>
                    <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain2"></div>
                    <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain3"></div>
                    <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain4"></div>
                    <div class="infoBtn" @click="dockModal=true">{{ $t('solutionInfo.infoBtn') }}</div>
                </div>
            </div>
        </div>
        <div class="boxMobile solution-M">
            <div class="solutionTitle" :class="{'solutionTitleEn': $i18n.locale==='en' }">{{ $t('solutionInfo.title') }}</div>
            <div class="my-tab" ref="myTabRef">
                <div 
                    class="my-tab-item" 
                    v-for="(item,index) in solutionInfo.solutionList" 
                    :ref="`tab${index}Ref`" 
                    :class="{ active: index === solutionActive }" 
                    :key="item.title"
                >
                    <div class="my-tab-text" @click="tabClick(index)">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <div class="solutionInfo">
                <div class="infoTextList">
                    <div
                        class="infoText"
                        v-for="(item2, index2) in solutionInfo.solutionList[solutionActive].infoTitleList"
                        :key="index2"
                        :class="{solutionActive: solutionActive === index2}"
                    >
                        <p>{{ item2 }}</p>
                    </div>
                </div>
                <div class="infoMain infoMain1" v-html="solutionInfo.solutionList[solutionActive].infoMain"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain1"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain2"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain3"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain4"></div>
                <div style="text-align: center;">
                    <div class="infoBtn" @click="dockModal=true">{{ $t('solutionInfo.infoBtn') }}</div>
                </div>
            </div>
        </div>
        <div class="principle">
            <div class="principleTitle" :class="{'principleTitleEn': $i18n.locale==='en' }" v-html="$t('principleTitle')"></div>
            <div class="principleVideo">
                <div class="videoBtn" @click="playVideo">
                    <img src="@/assets/images/capital/playIcon.png">
                    <span>{{$t('videoPlay')}}</span>
                </div>
            </div>
            <div class="principleImg">
                <img src="@/assets/images/capital/principleImgEN.png" alt="" v-if="$i18n.locale==='en'">
                <img src="@/assets/images/capital/principleImgCN.png" alt="" v-else>
            </div>
        </div>
        <div class="precedents">
            <div class="precedents_cnt">
                <div class="pre-left">
                    <h3 class="title" :class="{'titleEn': $i18n.locale==='en' }">{{ $t('timestampCase.title') }}</h3>
                    <div class="tab">
                        <div class="tab-name">
                            <ul>
                                <li 
                                    :class="tabactive == item.id ? 'active' : ''"
                                    v-for="item in courtRank"
                                    :key="item.id"
                                    @click="tabCourt(item)"
                                >
                                    {{ item.name }}
                                    <div class="active-line"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-cnt">
                            <ul>
                                <li
                                    v-for="item in conditionList"
                                    :key="item.id"
                                    @click="goTsa('case',item.id)"
                                >
                                    <p class="introduce" v-html="item.title"></p>
                                    <p class="caseNo">
                                        案号：{{ item.precedentNo }}
                                    </p>
                                </li>
                            </ul>
                            <div class="more">
                                <span @click="goTsa('case')"
                                    >查看更多 <i class="el-icon-right"></i
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pre-right">
                    <h3 class="title" :class="{'titleEn': $i18n.locale==='en' }">{{ $t('commonProblems.title') }}</h3>
                    <div class="qusion">
                        <!-- <h5>在线疑问解答</h5> -->
                        <div class="ipt-wrap">
                            <el-input
                                type="text"
                                placeholder="请输入您想要了解的问题或关键字"
                                v-model="keywords"
                                class="ipt"
                            >
                                <i
                                    class="el-icon-search el-input__icon"
                                    slot="suffix"
                                    @click="onSearch"
                                >
                                </i>
                            </el-input>
                        </div>
                        <ul class="cnt">
                            <li
                                v-for="item in questionsList"
                                :key="item.id"
                                @click="goTsa('wiki',item.id)"
                            >
                                <p v-html="item.question"></p>
                            </li>
                        </ul>
                        <div class="more">
                            <span @click="goTsa('wiki')">查看更多 <i class="el-icon-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="cnt">
                <dl class="left">
                    <dt>
                        <img src="@/assets/images/capital/bigLogo.png" alt="">
                    </dt>
                    <dd>
                        <h3>{{ $t('footer.webName') }}</h3>
                        <h5 v-if="$i18n.locale==='zh'">Capital Intellectual Property Services Association</h5>
                        <div class="info">
                            <p v-html="$t('footer.address')"></p> 
                            <p v-html="$t('footer.email')"></p>
                            <p>
                                <span>{{$t('footer.ICP')}}</span> 
                                <span>{{ $t('footer.record') }}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="right">
                    <div>
                        <h4>{{ $t('footer.support') }}</h4>
                        <p @click="gotoIndex" class="gotoIndexClass">
                            <img src="@/assets/images/capital/tsaLogo.png" width="235" alt="" v-if="$i18n.locale==='zh'">
                            <img src="@/assets/images/capital/logoEn.png" width="235" alt="" v-else>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="videoZhe" v-if="isShowVideo">
            <div class="vid">
                <video-player
                    class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                ></video-player>
                <span class="close" @click="closeVideo">
                    <i class="el-icon-close"></i>
                </span>
            </div>
        </div>
        <Docking :show="dockModal" @onClose="dockModal=false" />
        <template v-if="!token">
            <div class="fixOpen" v-show="fixOpen">
                <div class="cnt" v-if="deviceType=='PC'">
                    <span class="close" @click="(fixOpen=false)"></span>
                    <img src="@/assets/images/capital/fix_open.png" alt="" @click="onLogin" v-if="$i18n.locale==='zh'" />
                    <img src="@/assets/images/capital/fix_openEn.png" alt="" @click="onLogin" v-else />
                </div>
                <div class="cnt" v-else>
                    <span class="close" @click="(fixOpen=false)"></span>
                    <img src="@/assets/images/capital/fix_open_mobile.png" alt="" @click="onLogin" v-if="$i18n.locale==='zh'" />
                    <img src="@/assets/images/capital/fix_open_mobileEn.png" alt="" @click="onLogin" v-else />
                </div>
            </div>
            <div class="fixClose" :class="{ fixCloseEn: $i18n.locale==='en'}"  v-show="!fixOpen" @click="(fixOpen=true)">
                <p>{{ $t('newUser') }}</p>
                <img src="@/assets/images/capital/openArrow.png" alt="">
            </div>
        </template>
    </div>
</template>
  
<script>
import { channelLink } from "@/utils/channel.js";
import { hrefOpen } from "@/utils/link.js";
import {questionAnswer,precedent,precedentStatistics,precedentSearchByCondition} from "@/api/tsaService";
import {getMeApi} from '@/api/service';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import Carousel from "@/components/Carousel";
import Docking from "@/components/Docking";
var timer;
export default {
    data() {
        return {
            fixOpen:true,
            deviceType:'PC',
            token:'',
            userAccount:'',
            active: 0,
            courtRank: [
                {
                    id: 1001549,
                    name: "商业秘密",
                    type: "precedent_usage",
                },
                {
                    id: 970753,
                    name: "商标",
                    type: "precedent_column",
                },
                {
                    id: 971777,
                    name: "专利",
                    type: "precedent_column",
                },
                {
                    id: 972801,
                    name: "著作权",
                    type: "precedent_column",
                },
                {
                    id: 1001548,
                    name: "数字资产",
                    type: "precedent_usage",
                },
            ],
            conditionList: [],
            questionsList: [],
            keywords: "",
            tabactive: 1001549,
            swiperOptions: {
                notNextTick: true,
                //循环
                loop: false,
                loopAdditionalSlides: 0,
                //设定初始化时slide的索引
                initialSlide: 0,
                //自动播放
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                //滑动速度
                speed: 1500,
                //滑动方向
                direction: "horizontal",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                //小手掌抓取滑动
                // grabCursor : true,
            },
            accountNum: {},
            workActive: -1,
            workActive2:0,
            solutionActive: 0,
            percentage: 0,
            swiperOption: {
                slidesPerView: 6,
                spaceBetween: 0,
                notNextTick: true,
                loop: false,
                autoplay: 3000,
                direction: "horizontal",
                grabCursor: true,
                setWrapperSize: true,
                autoHeight: true,
                pagination: ".swiper-pagination",
                paginationClickable: true,
                navigation: {
                    prevEl: ".swiper-button-prev", //上一张
                    nextEl: ".swiper-button-next", //下一张
                },
                scrollbar: ".swiper-scrollbar", //滚动条
                mousewheelControl: true,
                observeParents: true,
                hideOnClick: true,
            },
            dockModal:false,
            isShowVideo: false,
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },
        };
    },

    components: {
        swiper,
        swiperSlide,
        Carousel,
        Docking
    },
    watch: {
        workActive(newVal, oldVal) {
            if (newVal !== oldVal) {
                timer && clearInterval(timer);
                timer = null;
                this.percentage = 0;
                timer = setInterval(() => {
                    if (this.percentage < 100) {
                        this.percentage = this.percentage + 2;
                    }
                }, 200);
            }
        },
        percentage(newVal, oldVal) {
            if (newVal === 100) {
                setTimeout(() => {
                    if (
                        this.workActive ==
                        this.iprWorkInfo.listInfo.length - 1
                    ) {
                        this.workActive = 0;
                    } else {
                        this.workActive = this.workActive + 1;
                    }
                }, 500);
            }
        },
    },
    computed: {
        addressInfo(){
            return[
                {
                    img: require("@/assets/images/newIndex/address1.png"),
                    title: this.$t("display.sec1.title"),
                    info: this.$t("display.sec1.info"),
                },
                {
                    img: require("@/assets/images/newIndex/address2.png"),
                    title: this.$t("display.sec2.title"),
                    info: this.$t("display.sec2.info"),
                },
                {
                    img: require("@/assets/images/newIndex/address3.png"),
                    title: this.$t("display.sec3.title"),
                    info: this.$t("display.sec3.info"),
                },
            ]
        },
        iprWorkInfo(){
            return{
                title:  this.$t("iprWork.iprWorkInfo.title"),
                workLogo: require("@/assets/images/newIndex/workLogo.png"),
                listInfo: [
                    {
                        leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec1.leftTitle"),
                        leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec1.leftInfo"),
                        leftImg: require("@/assets/images/newIndex/workLeft1.png"),
                        rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightTitle"),
                        rightList1: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list6"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list7"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list8"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list9"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list10"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list11"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list12"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list13"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list14"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list15"),
                            ],
                            title: this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.title"),
                        },
                        rightList2: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list6"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list7"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list8"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list9"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list10"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list11"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list12"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list13"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list14"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list15"),
                            ],
                            title:this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.title"),
                        },
                    },
                    {
                        leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec2.leftTitle"),
                        leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec2.leftInfo"),
                        leftImg: require("@/assets/images/newIndex/workLeft2.png"),
                        rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightTitle"),
                        rightList1: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list6"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list7"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list8"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list9"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list10"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list11"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list12"),
                            ],
                            title: this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.title"),
                        },
                    },
                    {
                        leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec3.leftTitle"),
                        leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec3.leftInfo"),
                        leftImg: require("@/assets/images/newIndex/workLeft3.png"),
                        rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightTitle"),
                        rightTitle2:this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightTitle2"),
                        rightList1: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list6"),
                            ],
                            title: this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.title"),
                        },
                        rightList2: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list6"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list7"),
                            ],
                            title: this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.title"),
                        },
                    },
                    {
                        leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec4.leftTitle"),
                        leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec4.leftInfo"),
                        leftImg: require("@/assets/images/newIndex/workLeft4.png"),
                        rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightTitle"),
                        rightList1: {
                            list: [
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list1"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list2"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list3"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list4"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list5"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list6"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list8"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list9"),
                                this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list10"),
                            ],
                            title: this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.title"),
                        },
                    },
                ],
            }
        },
        solutionInfo(){
            return{
                leftIcon: require("@/assets/images/newIndex/leftIcon.png"),
                rightIcon: require("@/assets/images/newIndex/rightIcon.png"),
                solutionList: [
                    {
                        title: this.$t("solutionInfo.solutionList.sec1.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec1.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec1.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec1.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec1.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec1.infoTitleList.list5"),
                        ],
                        infoMain:this.$t("solutionInfo.solutionList.sec1.infoMain"),
                        infoMain1: this.$t("solutionInfo.solutionList.sec1.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec1.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec1.infoMain3"),
                        infoMain4: this.$t("solutionInfo.solutionList.sec1.infoMain4"),
                        infoBtn: this.$t("solutionInfo.solutionList.sec1.infoBtn"),
                        solutionIcon: require("@/assets/images/newIndex/solution1.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec2.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list5"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list6"),
                            this.$t("solutionInfo.solutionList.sec2.infoTitleList.list7"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec2.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec2.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec2.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec2.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec2.infoMain3"),
                        solutionIcon: require("@/assets/images/newIndex/solution2.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec3.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec3.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec3.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec3.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec3.infoTitleList.list4"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec3.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec3.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec3.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec3.infoMain2"),
                        solutionIcon: require("@/assets/images/newIndex/solution3.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec4.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list5"),
                            this.$t("solutionInfo.solutionList.sec4.infoTitleList.list6"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec4.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec4.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec4.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec4.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec4.infoMain3"),
                        solutionIcon: require("@/assets/images/newIndex/solution4.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec5.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list5"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list6"),
                            this.$t("solutionInfo.solutionList.sec5.infoTitleList.list7"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec5.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec5.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec5.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec5.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec5.infoMain3"),
                        infoMain4:this.$t("solutionInfo.solutionList.sec5.infoMain4"),
                        solutionIcon: require("@/assets/images/newIndex/solution5.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec6.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec6.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec6.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec6.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec6.infoTitleList.list4"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec6.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec6.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec6.infoMain1"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec6.infoMain3"),
                        infoMain4:this.$t("solutionInfo.solutionList.sec6.infoMain4"),
                        solutionIcon: require("@/assets/images/newIndex/solution6.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec7.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec7.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec7.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec7.infoTitleList.list3"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec7.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec7.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec7.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec7.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec7.infoMain3"),
                        solutionIcon: require("@/assets/images/newIndex/solution7.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec8.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list5"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list6"),
                            this.$t("solutionInfo.solutionList.sec8.infoTitleList.list7"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec8.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec8.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec8.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec8.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec8.infoMain3"),
                        solutionIcon: require("@/assets/images/newIndex/solution8.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec9.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list5"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list6"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list7"),
                            this.$t("solutionInfo.solutionList.sec9.infoTitleList.list8"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec9.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec9.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec9.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec9.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec9.infoMain3"),
                        infoMain4: this.$t("solutionInfo.solutionList.sec9.infoMain4"),
                        solutionIcon: require("@/assets/images/newIndex/solution9.png"),
                    },
                    {
                        title: this.$t("solutionInfo.solutionList.sec10.title"),
                        infoTitleList: [
                            this.$t("solutionInfo.solutionList.sec10.infoTitleList.list1"),
                            this.$t("solutionInfo.solutionList.sec10.infoTitleList.list2"),
                            this.$t("solutionInfo.solutionList.sec10.infoTitleList.list3"),
                            this.$t("solutionInfo.solutionList.sec10.infoTitleList.list4"),
                            this.$t("solutionInfo.solutionList.sec10.infoTitleList.list5"),
                        ],
                        infoTitle:this.$t("solutionInfo.solutionList.sec10.infoTitle"),
                        infoMain:this.$t("solutionInfo.solutionList.sec10.infoMain"),
                        infoMain1:this.$t("solutionInfo.solutionList.sec10.infoMain1"),
                        infoMain2:this.$t("solutionInfo.solutionList.sec10.infoMain2"),
                        infoMain3:this.$t("solutionInfo.solutionList.sec10.infoMain3"),
                        solutionIcon: require("@/assets/images/newIndex/solution10.png"),
                    },
                ]
            }
        }
    },

    created() {
        console.log(this.$i18n.locale)
        this.getPrecedentList();
        this.getQuestionList();
        this.getprecedentStatistics();
        this.initWork();
        getMeApi().then((res) => {
                this.userInfo = res;
                this.token = res.accessToken;
            })
            .catch((err) => {
                console.log(err);
            });
        if (this.$store.state.userInfo) {
            this.token = this.$store.state.userInfo.me.accessToken;
            console.log(this.token);
        }
    },

    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize)
    },
    deactivated () {
        window.removeEventListener('resize', this.resize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize)
    },
    methods: {
        resize () {
            if (document.body.clientWidth > 768) {
                this.deviceType = 'PC';
            } else {
                this.deviceType = 'MOBILE';
            }
        },
        gotoIndex () {
            window.open(process.env.VUE_APP_TSA)
        },
        changeLang(){
            if(this.$i18n.locale=='zh'){
                this.$i18n.locale='en'
                this.$setlocalStorage('language','en')
            }else{
                this.$i18n.locale='zh'
                this.$setlocalStorage('language','zh')
            }
        },
        onLogin() {
            if(!this.token){
                let params = Object.assign(this.$route.query);
                // if (this.$route.query.channelCode !== undefined) {
                //     params.channelCode = this.$route.query.channelCode ? this.$route.query.channelCode : "bdipr";
                // }
                this.$router.push({
                    path:'/login',
                    query:params,
                })
            }else{
                let copyRightUrl;
                if(this.deviceType == 'PC'){
                    copyRightUrl=process.env.VUE_APP_COPYRIGHT
                }else{
                    copyRightUrl=process.env.VUE_APP_COPYRIGHT_M
                }
                let url = copyRightUrl+"/time/ApplyTime?access_token="+this.token+"&language="+this.$getlocalStorage("language")+"&partnerType=1"
                let link = document.createElement("a");
                link.style.display = "none";
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
        },
        tab(n) {
            this.active = n;
        },
        async getprecedentStatistics() {
            let res = await precedentStatistics();
            if (res) {
                this.accountNum = res;
                console.log(res);
            }
        },
        getPrecedentList() {
            let that = this;
            precedentSearchByCondition({
                size: 3,
                usageDictionaryIds: 1001549,
                page: 1,
                sort: 1,
            })
                .then((res) => {
                    console.log(res);
                    that.conditionList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getQuestionList() {
            let that = this;
            questionAnswer({
                size: 5,
                sourceType: 952310,
                page: 1,
                sort: 1,
            })
                .then((res) => {
                    console.log(res);
                    that.questionsList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        tabCourt(data) {
            console.log(data);
            let httpUrl =
                data.id === 1001549
                    ? precedentSearchByCondition
                    : data.id === 1001548
                    ? precedentSearchByCondition
                    : precedent;
            let paramsObj =
                data.id === 1001549
                    ? { usageDictionaryIds: 1001549 }
                    : data.id === 1001548
                    ? { usageDictionaryIds: 1001548 }
                    : { dictionaryId: data.id };
            paramsObj.size = 3;
            paramsObj.sort = 1;
            paramsObj.page = 1;
            this.tabactive = data.id;
            let that = this;
            httpUrl(paramsObj)
                .then((res) => {
                    console.log(res);
                    that.conditionList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        goTsa(path,id) {
            if(this.deviceType=='MOBILE'){
                let router;
                if(path=='case'){
                    router=`/precedent/detail/${id}`;
                }else{
                    router=`/question/detail/${id}`;
                }
                window.open(channelLink(process.env.VUE_APP_M_TSA + router,"bdipr"))
            }else{
                if(id){
                    let newID=TsaConvertId(id, path);
                    window.open(process.env.VUE_APP_TSA+'/'+path+'/'+newID+'.html')
                }else{
                    window.open(process.env.VUE_APP_TSA+'/'+path+'.html')
                }
            }
            
        },
        onSearch() {
            if(this.deviceType=='MOBILE'){
                window.open(
                    process.env.VUE_APP_M_TSA +`/question?keywords=${this.keywords}`
                );
            }else{
                window.open(
                    process.env.VUE_APP_TSA +`/wiki.html?swd=${encodeURIComponent(this.keywords)}`
                );
            }
            
        },
        copy() {
            var clipboard = new this.Clipboard(".copybtn");
            var that = this;
            clipboard.on("success", (e) => {
                // console.log('复制成功')
                // 释放内存
                that.$message({
                    message: "复制成功",
                    type: "success",
                });
                clipboard.destroy();
            });
            clipboard.on("error", (e) => {
                // 不支持复制
                console.log("该浏览器不支持自动复制");
                that.$message.error("该浏览器不支持自动复制");
                // 释放内存
                clipboard.destroy();
            });
        },
        // 新版首页
        workClick(item, index) {
            if (this.workActive !== index) {
                this.percentage = 0;
                this.workActive = index;
            }
        },
        tabCardClick(index) {
            this.workActive2 = index;
            // 触发滑动方法
            this.tabCardScrollLeftTo(index);
        },
        // 滑动
        tabCardScrollLeftTo(index) {
            let ref = `tabCard${index}Ref`;
            // 获取myTabRef的DOM元素，即类名为my-tab的标签
            let Tabs = this.$refs.myTabCardRef;
            // 获取当前点击的某一个tab的的DOM元素，即类名为my-tab-item的标签
            let TabItem = this.$refs[ref][0];
            // 计算位移偏差
            let to = TabItem.offsetLeft - (Tabs.offsetWidth - TabItem.offsetWidth) / 2;
            Tabs.scrollLeft = to;
        },
        // ************定制化解决方案***********
        solutionClick(item, index) {
            this.solutionActive = index;
        },
        prevBtn() {
            this.$refs.solutionSwiper.swiper.slidePrev();
        },
        nextBtn() {
            this.$refs.solutionSwiper.swiper.slideNext();
        },
        tabClick(index) {
            this.solutionActive = index;
            // 触发滑动方法
            this.scrollLeftTo(index);
        },
        // 滑动
        scrollLeftTo(index) {
            const ref = `tab${index}Ref`;
            // 获取myTabRef的DOM元素，即类名为my-tab的标签
            const Tabs = this.$refs.myTabRef;
            // 获取当前点击的某一个tab的的DOM元素，即类名为my-tab-item的标签
            const TabItem = this.$refs[ref][0];
            // 计算位移偏差
            const to = TabItem.offsetLeft - (Tabs.offsetWidth - TabItem.offsetWidth) / 2;
            Tabs.scrollLeft = to;
        },


        initWork() {
            this.workActive = 0;
        },
        addressClick(index) {
            let url =
                index === 0
                    ? "https://www.tsa.cn/news/detail/4235"
                    : index === 1
                    ? "https://www.tsa.cn/news/detail/4122"
                    : index === 2
                    ? "https://www.tsa.cn/honor"
                    : "";
            hrefOpen(url);
        },
        mouseOver(data) {
            clearInterval(timer);
            timer=null;
        },
        mouseLeave(data) {
            timer = setInterval(() => {
                if (this.percentage < 100) {
                    this.percentage = this.percentage + 2;
                }
            }, 200);
        },
        playVideo() {
            let filePath;
            if(this.$i18n.locale==='en'){
                filePath = 'https://evidence-public.oss-cn-beijing.aliyuncs.com/ipr/3%E6%9C%887%E6%97%A5.mp4';
            }else{
                filePath = 'https://evidence-public.oss-cn-beijing.aliyuncs.com/ipr/%E5%90%88%E5%B9%B6.mp4';
            }

            this.playerOptions.sources.push({
                src: filePath,
                type: "video/mp4",
            });
            this.isShowVideo = true;
        },
        closeVideo() {
            this.playerOptions.sources.shift();
            this.isShowVideo = false;
        },
    },
};
</script>
<style src="@/assets/css/home.less" lang="less" scoped></style>
<style>
.precedents .el-input__icon {
    line-height: 35px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.gotoIndexClass {
    cursor: pointer;
}
</style>
  