import router from "@/router";
import request from "@/api/index";
import {set, get } from '@/utils/localStorage'
import {getMeApi} from '@/api/service';

router.beforeEach((to, from, next) => {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (to.meta.title) {
        document.title = to.meta.title
        console.log(to.meta.title)
    }
    if (to.matched.length === 0) {
        //如果未匹配到路由
        from.name ? next({ name: from.name }) : next('/');
    }
    //进入登录清空之前缓存
    if (to.path == '/login') {
        localStorage.removeItem('queryData')
    }
    //拿当前参数存入缓存
    let queryData = to.query;
    let obj = Object.keys(queryData)
    if (obj.length !== 0) {
        queryData = Object.assign({}, get('queryData'), queryData);
        set("queryData", queryData);
    }
    if(queryData.language && !(queryData.language=='null')){
        set("language", queryData.language);
    }else if(!get("language")){
        set("language", 'zh');
    }

    const loginPath = [
        '/',
        '/identification'
    ];
    if (loginPath.indexOf(to.path) !== -1) {
        next();
    }else{
        getMeApi().then((res) => {
            request({
                method: 'get',
                url: '/user',
                stopLoading: true
            }).then(async(res) => {
                let link;
                if(flag){
                    link=process.env.VUE_APP_COPYRIGHT_M
                }else{
                    link=process.env.VUE_APP_COPYRIGHT
                }
                window.location.href = link+"/time/ApplyTime?access_token="+res.accessToken+"&language="+get("language")+'&partnerType=1'
            })
        }).catch((err) => {
            next()
        })
    }
})