import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        meta: {
            title: "知识产权保护平台_首都知识产权服务业协会",
        },
        component: index,
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "登录",
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/login.vue"),
    },
    {
        path: "/reg",
        name: "reg",
        meta: {
            title: "注册",
        },
        component: () =>
            import(/* webpackChunkName: "reg" */ "../views/reg.vue"),
    },
    {
      path: "/forget",
      name: "forget",
      meta: {
          title: "忘记密码",
      },
      component: () =>
          import(/* webpackChunkName: "forget" */ "../views/forget.vue"),
    },
    {
        path: "/identification",
        name: "identification",
        meta: {
            title: "知产鉴定",
        },
        component: () =>
            import(/* webpackChunkName: "identification" */ "../views/identification.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
