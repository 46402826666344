import Vue from "vue";
import VueI18n from "vue-i18n";
import { get } from "@/utils/localStorage";

import enLocale from "element-ui/lib/locale/lang/en"; //导入element ui中的默认英文配置
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //导入element ui中的默认中文配置
import zh from "./zh/zh";
import en from "./en/en";
import loginZh from "./zh/loginZh";
import loginEn from "./en/loginEn";
Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale,
        ...en, //把导入的英文词条合并
        ...loginEn,
    },
    zh: {
        ...zhLocale,
        ...zh, //把导入的中文词条合并
        ...loginZh,
    },
};
const i18n = new VueI18n({
    locale: get("language")? get("language") : "zh",
    messages,
});
export default i18n;
