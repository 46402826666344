export default{
    login:{
        slogan1:'<p>Protect your business secret and intellectual</p><p> property by UniTrust TimeStamp at any time</p>',
        slogan2:'——Capital Intellectual Property Services Association',
        tab1:'Password Login',
        tab2:'Security Code Login',
        phone:'Enter your phone number',
        verPhone:'Please enter the correct cell phone number',
        code:'Enter your security code',
        sendCode:'The verification code has been sent, please pay attention to receive',
        getCode:'Get Security Code',
        againGetCode:'Get Back',
        userName:'Enter your account/phone number/Email',
        verUserName:'Please enter the correct mobile phone number or mailbox',
        userName2:'Enter your phone number/Email',
        password:'Enter your password',
        verPassword:'Please Enter 6-20 letters or numbers',
        confirmPwd:'Confirm your password',
        contrastPwd:'The two passwords do not match',
        varSlide:'Please verify the slider first',
        agreement:'Please read and check the agreement',
        signIn:'Sign In',
        signUp:'Sign Up',
        read:'I have read and accepted',
        regAgreement:'《User Registration and Use Agreement》',
        and:'and',
        privacyAgreement:'《Privacy Protection Agreement》',
        goReg:'No account？Sign up',
        goReg2:'Account not registered, please register first',
        forgetPwd:'Forgot password？',
        goLogin:'Already a member？Sign In',
        goLogin2:'Sign Up',
        goLogin3:'Sign In',
        vError:'Validation error',
        authenticate:'Verify identity',
        setPwd:'Set new password',
        over:'Complete',
        next:'Next',
        previous:'Previous',
        setPwdSuccess:'Password reset succeeded!',
        regSuccess:'Registration successful',
        alreadyReg:'The account has been registered using the UniTrusted TimeStamp account. Please log in directly.',
    }
}