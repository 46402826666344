import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/common.css";
import i18n from "./lang";
import {set, get } from '@/utils/localStorage'
import layer from "vue-layer";
import "vue-layer/lib/vue-layer.css";
import "@/utils/permission";
import VideoPlayer from 'vue-video-player'
import {
    Dialog,
    Input,
    Button,
    Select,
    Option,
    InputNumber,
    Checkbox,
    Carousel,
    CarouselItem,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Icon,
    Row,
    Col,
    Loading,
    Message,
    MessageBox,
    Notification,
    Progress
} from "element-ui";
import AMap from 'vue-amap';
Vue.use(AMap);

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(RadioGroup);


Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message=Message;

Vue.prototype.$layer = layer(Vue);
Vue.config.productionTip = false;
Vue.prototype.$setlocalStorage = set;
Vue.prototype.$getlocalStorage = get;
import { Mymessage, diaMessage } from '@/utils/common';
Vue.prototype.$msg = Mymessage;
Vue.prototype.$diamsg = diaMessage;

AMap.initAMapApiLoader({
    // 高德的key
    key: "437806195503b54623edf733370a32c4",
    // 插件集合
    plugin: [
        "AMap.Scale", //右下角缩略图插件 比例尺
        "AMap.OverView", //地图鹰眼插件
        "AMap.ToolBar", //地图工具条
    ],
    uiVersion: "1.0",
    v: "1.4.8"
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
