import axios from "axios";
import { Message, Loading } from "element-ui";
import {set, get } from '@/utils/localStorage'
import zhCN from "element-ui/lib/locale/lang/zh-CN";

var baseURL;
if (process.env.NODE_ENV === 'development') {
    baseURL = '/api/api';
} else {
    baseURL = '/opus/api';
}
let loading;
let duration = 2 * 1000;

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "数据加载中...",
        background: "rgba(0,0,0,.7)"
    });
}

function stopLoading() {
    loading.close();
}

const service = axios.create({
    baseURL,
    timeout: 86400000,
    headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
        // 'content-type': "application/json;charset=UTF-8"
    }
});


service.interceptors.request.use(
    config => {
        if (!config.stopLoading) {
            startLoading();
        }
        if(get("language") && get("language")==='en'){
            config.headers['Accept-Language']  = 'en-US'
        }else{
            config.headers['Accept-Language']  = 'zh-CN'
        }
        return config;
    },
    error => {
        loading&&stopLoading();
        return Promise.reject(error);
    }
);


service.interceptors.response.use(
    response => {
        loading&&stopLoading();
        const { status, data } = response;
        if (response instanceof Blob) {
            return response;
        }
        if (status !== 200) {
            Message({
                message: data.message || "Error",
                type: "error",
                duration
            });
            return false;
        } else {
            return data
        }
    },
    error => {
        loading&&stopLoading();
        return Promise.reject(error.response);
    }
);

export default service;