export function set(key, value) {
    var curTime = new Date().getTime();
    return window.localStorage.setItem(key, JSON.stringify({ data: value, time: curTime }));
}

export function get(key, exp = 86400000) {
    var data = localStorage.getItem(key);
    var dataObj = JSON.parse(data);
    if (dataObj) {
        if (new Date().getTime() - dataObj.time > exp) {
            console.log('信息已过期');
            //store.commit('setuserinfo', '')
            localStorage.removeItem(key)
            return null
                // router.replace({ path: "/" })
        } else {
            //console.log("data="+dataObj.data);
            //console.log(JSON.parse(dataObj.data));
            var dataObjDatatoJson = dataObj.data;
            return dataObjDatatoJson;
        }
    } else {
        return null
    }
}