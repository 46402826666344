/*
 * @Description: 
 * @Version: 1.0
 * @Autor: zhangle
 * @Date: 2022-06-14 17:49:14
 * @LastEditors: zhangle
 * @LastEditTime: 2022-07-01 10:23:16
 */
import qs from 'qs'
const channelCode = [
    {
        tsa: 'mailtsa' // 官网
    }
]
/**
 * 获取url后参数
 */
 export const GetRequest = () => {
    let url = location.search; //获取url中"?"符后的字串
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
        }
    }
    return theRequest;
};

export const channelLink = (url, channelCode) => {
    if (window.location.href.indexOf('?channelCode') > -1 || window.location.href.indexOf('&channelCode') > -1) {

        let channelCodeStr = ''
        
        let urlChannelCode = GetRequest().channelCode // 路径上的 channelCode
        
        channelCodeStr = 'channelCode=' + ( urlChannelCode ? urlChannelCode : channelCode )

        if (url.indexOf('?') === -1) { // 不存在 ？号
            return url + '?' + channelCodeStr
        } else {
            return url + '&' + channelCodeStr
        }
    } else {
        return url
    }
}
export const channelLogin = (url, $route) => {
    let params = Object.assign($route.query, {
        redirectUrl: encodeURI(window.location.origin + '/index&'),
    })
    if ($route.query.channelCode !== undefined) {
        params.channelCode =( $route.query.channelCode ? $route.query.channelCode : 'mailtsa' )
        url += encodeURI(window.location.origin + '/index?channelCode=' + params.channelCode + '&' + qs.stringify(params))
    } else {
        url += encodeURI(window.location.origin + '/index&' + qs.stringify(params))
    }
    return url
}