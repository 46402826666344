import axios from "axios";
import { Message, Loading } from "element-ui";

var baseURL;
if (process.env.NODE_ENV === 'development') {
    baseURL = '/api/content/api/';
} else {
    baseURL = '/content/api/';
    // baseURL = 'https://www.tsa.cn/api/';
}
let loading;
let duration = 2 * 1000;

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "数据加载中...",
        background: "rgba(0,0,0,.7)"
    });
}

function stopLoading() {
    loading.close();
}



axios.defaults.headers = {}

const service = axios.create({
    baseURL,
    timeout: 86400000,
    headers: {}
    // headers: {
    //     // 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
    //     'content-type': "application/json;charset=UTF-8"
    // }
});


service.interceptors.request.use(
    config => {
        // if (!config.stopLoading) {
        //     startLoading();
        // }
        return config;
    },
    error => {
        console.log(error); // for debug
        // stopLoading();
        return Promise.reject(error);
    }
);


service.interceptors.response.use(
    response => {
        // stopLoading();
        const { status, data } = response;
        if (response instanceof Blob) {
            return response;
        }
        if (status !== 200) {
            Message({
                message: data.message || "Error",
                type: "error",
                duration
            });
            return false;
        } else {
            return data
        }
    },
    error => {
        // stopLoading();
        let res = error.response;
        // /let message = "";
        console.log(error.response);
        // if (res && res.status === 500) {
        //     Message({
        //         message: res.data.message || '服务器异常',
        //         type: "error",
        //         duration
        //     });
        // } else {
        //     Message({
        //         message: res.data.message || '服务器异常',
        //         type: "error",
        //         duration
        //     });
        //     return res
        // }
        return Promise.reject(error.response.data);
    }
);

export default service;